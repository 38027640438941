  
#mainbody img {
    border: 0;
}

#main {
    background: #FFF;
    overflow: auto;
    width: 750px;
    margin: 15px auto;
}

#mainbody {
	background: #FFF;
	width: auto;
	margin: 0 auto;
	display: table;
}

#mainbody #qr-canvas {
    display: none;
}

#mainbody #iembedflash {
    margin: 0;
    padding: 0;
}

#mainbody #mp1 {
    text-align: center;
    font-size: 25px;
}

#mainbody #mp2 {
    text-align: center;
    font-size: 25px;
    color: red;
}

#mainbody .selector {
    /* border: solid; */
    cursor: pointer;
    border-width: 3px 3px 1px;
    margin: 0 0 -5px;
    padding: 0;
}

#mainbody #outdiv {
    width: 250px;
    height: 250px;
    margin-top: 10px; 
    background:url(../img/qr-code.jpg);
    background-size:cover;
}

#mainbody #result {
    width: 70%;
    padding: 20px;
    border: 2px solid #AAA;
}

#mainbody #imghelp {
	position: relative;
	left: 0;
	top: -135px;
	z-index: 100;
	font: 18px arial,sans-serif;
	background: #f0f0f0;
	margin-left: 0;
	margin-right: 0;
	padding-top: 0;
	padding-bottom: 0;
}

#mainbody p.helptext {
    margin-top: 54px;
    font: 18px arial,sans-serif;
}

#mainbody p.helptext2 {
    margin-top: 100px;
    font: 16px arial,sans-serif;
}

#mainbody .tsel {
    padding: 0;
}

#mainbody #tooltip1 {
    position: relative;
    text-decoration: none;
    font: 15px arial,sans-serif;
    text-align: center;
    color: red;
}

#mainbody #tooltip1 a {
    text-decoration: none;
    color: red;
}

#mainbody #tooltip1 a span {
    display: none;
    color: #FFF;
}

#mainbody #tooltip1 a:hover span {
    text-decoration: none;
    display: block;
    position: absolute;
    width: 250px;
    background-color: #ddd;
    height: 50px;
    left: -10px;
    top: -10px;
    color: #000;
    padding: 5px;
}

#mainbody p.quote1 {
    text-align: left;
    margin-bottom: 3em;
    font-weight: 700;
    margin-left: 10%;
    margin-right: 10%;
    color: #000;
}

#mainbody div.button {
    border: 2px solid #333;
    border-radius: 15px;
    width: 100px;
    cursor: pointer;
    font-weight: 700;
}

#mainbody ul.faq {
    font-size: 20px;
    text-align: left;
}

#mainbody ul.resp {
    font-size: 15px;
    list-style-type: none;
    margin-top: 10px;
    margin-bottom: 10px;
}

#mainbody p.about {
    font-size: 20px;
    text-align: left;
    text-indent: 30pt;
}

#mainbody #header,#mainbody #footer {
    background: #FFF;
}

#mainbody #qrfile,#mainbody #v {
    width: 250px;
    height: 250px;
}

#mainbody ul,#mainbody #header ul {
    margin-bottom: 0;
    margin-right: 40px;
}

#mainbody li,#mainbody #header li {
    display: inline;
    padding-right: .5em;
    padding-left: .5em;
    font-weight: 700;
    border-right: 1px solid #333;
}

#mainbody li a,#mainbody #header li a {
    text-decoration: none;
    color: #000;
}

#mainbody .helpspan{
    width: 100%;
    display: block;
    text-align: center;
    margin-top: 105px;
}
#mainbody .resultText{
    font-size: 26px;
    margin: 15px;
}
#mainbody #downloadQr{
	display: none;
    margin-top: 10px;
}

#mainbody #footer a,a {
    color: #000;
}

#mainbody .createArea{
    width: 50%;
    margin: 0 auto;
}

#mainbody #imghelp{
opacity:0;
}
#mainbody #imghelp input[type="file"] {
	display: block;
	height: 250px;
	margin-top: -120px;
	width: 250px;
}
li.rc-time-picker-panel-select-option-disabled{
display:none;
}
.rc-time-picker-panel-select { 
	width: 50%!important; 
}
#date-pick{
	width: 100% !important;
} 
#form-success{
	min-height: 500px;
	display:none;
}
#form-success p{
	text-align:center;
	color:green;
    padding:10px;
}
.spn-error{
	color: #d22424;
}
.spn-success{
	color:green;
	text-align:center;
}
#qr-scan video { 
	margin: 0 auto;
}
.rc-time-picker-panel-input  {
	visibility:hidden;
}
.rc-time-picker-panel-input-wrap {
	visibility: hidden;
	border: 0;
	height: 0;
}
.birthdate > div > div {
	padding:0 !important;
}
